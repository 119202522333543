.json-format-tool {
    display: flex;
    flex-direction: row;
}

.text-area {
    width: calc(50% - 7.5pt);
}

.snippet-area {
    margin-left: 15pt;
    width: calc(50% - 7.5pt);
}
