$tools-list-width: 240px;

h2 {
  margin-bottom: 15pt;
}

.app {
  margin: 10pt 15pt;
}

.by-line {
  font-style: italic;
  font-size: 80%;

  a {
    color: #fd6d01;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.app-header {
  position: absolute;
  top: 0;
  left: 0;

  h1 {
    display: inline-block;
  }

  .bx--btn.bx--btn--icon-only.bx--tooltip__trigger.button-menu .bx--btn__icon {
    width: 32px;
    height: 32px;
  }
}

.content {
  margin-top: 64px;
  position: relative;
}

.bx--btn--tertiary {
  color: #fd6d01;
  border-color: #fd6d01;
  background-color: #ffffff;

  &:hover {
    background-color: #fd6d01;
  }
}

.tools-list {
  display: inline-block;
  width: $tools-list-width;
  margin-left: calc(-#{$tools-list-width} - 15pt);
  overflow: hidden;
  transition: margin 0.2s;

  &.tools-list-active {
    margin-left: 0;

    + .tool-area {
      width: calc(100% - #{$tools-list-width} - 15pt);
    }
  }
}

.tool-area {
  display: inline-block;
  margin-left: 15pt;
  width: 100%;
  position: absolute;
  transition: width 0.2s;
}

.tools-list-item {
  width: 100%;

  &.bx--btn--primary {
    border-width: 1px;
    border-style: solid;
    border-color: transparent;
    background-color: #fd6d01;
    color: #ffffff;
  }
}
