.markdown-preview-tool {
    display: flex;
    flex-direction: row;
}

.editor {
    width: calc(50% - 7.5pt);
}

.output-area {
    margin-left: 15pt;
    width: calc(50% - 7.5pt);

    iframe {
        display: block;
        width: 100%;
        height: calc(100% - 24px);
    }
}
