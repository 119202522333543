.text-area-with-highlight {
    &.container,
    .backdrop,
    textarea {
        width: 100%;
    }

    &.container {
        display: block;
        margin: 0 auto;
        transform: translateZ(0);
        -webkit-text-size-adjust: none;
    }

    .backdrop {
        position: absolute;
        z-index: 1;
        background-color: #f4f4f4;
        overflow: auto;
        pointer-events: none;
        transition: transform 1s;
        top: 25px;
        height: calc(100% - 25px);
    }

    .highlights {
        white-space: pre-wrap;
        word-wrap: break-word;
        color: transparent;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.43;
        letter-spacing: 0.16px;
        padding: 11px 16px 11px 16px;
    }

    // iOS adds 3px of (unremovable) padding to the left and right of a textarea, so adjust highlights div to match (in JS)
    .highlights-ios {
        padding-left: 19px;
        padding-right: 19px;
    }

    textarea {
        z-index: 2;
        border-radius: 0;
        background-color: transparent;
    }

    mark {
        border-radius: 3px;
        color: transparent;
        background-color: #9ef0f0;
    }
}
